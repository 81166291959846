/* eslint-disable import/first */

// #region ##################################################################################### BACKGROUND
import background_login from "@theme/images/bg/login.png";
// #endregion

// #region ##################################################################################### SHAPE
import shape_not_found from "@theme/images/shape/not-found.png";
import shape_enlace_icon from "@theme/images/shape/enlacelab-logo-icon.png";
import shape_enlace_logo_small from "@theme/images/shape/enlacelab-logo-small.png";
import shape_enlace_logo from "@theme/images/shape/enlacelab-logo.png";
import shape_alert_success from "@theme/images/shape/alert-success.png";
import shape_alert_warning from "@theme/images/shape/alert-warning.png";
import shape_alert_error from "@theme/images/shape/alert-error.png";
import shape_alert_info from "@theme/images/shape/alert-info.png";
// #endregion

// #region ##################################################################################### ICON
import icon_back from "@theme/images/icon/back.png";
import icon_calendar from "@theme/images/icon/calendar.png";
import icon_close from "@theme/images/icon/close.png";
import icon_create from "@theme/images/icon/create.png";
import icon_delete_tinted from "@theme/images/icon/delete-tinted.png";
import icon_delete from "@theme/images/icon/delete.png";
import icon_document from "@theme/images/icon/document.png";
import icon_download_cloud from "@theme/images/icon/download-cloud.png";
import icon_download from "@theme/images/icon/download.png";
import icon_edit_tinted from "@theme/images/icon/edit-tinted.png";
import icon_edit from "@theme/images/icon/edit.png";
import icon_external_link from "@theme/images/icon/external-link.png";
import icon_eye_off from "@theme/images/icon/eye-off.png";
import icon_eye_on from "@theme/images/icon/eye-on.png";
import icon_image_file from "@theme/images/icon/image-file.png";
import icon_logout from "@theme/images/icon/logout.png";
import icon_menu from "@theme/images/icon/menu.png";
import icon_next from "@theme/images/icon/next.png";
import icon_pdf from "@theme/images/icon/pdf.png";
import icon_search from "@theme/images/icon/search.png";
import icon_password from "@theme/images/icon/password.png";
// #endregion

// #region ##################################################################################### MISC
import misc_enlace_icon_vector from "@theme/images/misc/enlacelab-logo-icon.svg";
import misc_enlace_logo_small_vector from "@theme/images/misc/enlacelab-logo-small.svg";
import misc_enlace_logo_vector from "@theme/images/misc/enlacelab-logo.svg";
// #endregion

/** Objeto **I**mage**P**rovider que almacena todas las rutas de todas las imágenes y contenido de la aplicación. */
const IP = {
  /** Imágenes utilizadas como **fondo de pantalla** (imágenes grandes). */
  bg: {
    Login: background_login,
  },

  /** Imágenes utilizadas como **contenido** (imágenes medianas). */
  shape: {
    not_found: shape_not_found,
    enlace_icon: shape_enlace_icon,
    enlace_logo_small: shape_enlace_logo_small,
    enlace_logo: shape_enlace_logo,
    alert_success: shape_alert_success,
    alert_warning: shape_alert_warning,
    alert_error: shape_alert_error,
    alert_info: shape_alert_info,
  },

  /** Imágenes utilizadas como **íconos** (imágenes pequeñas). */
  icon: {
    back: icon_back,
    calendar: icon_calendar,
    close: icon_close,
    create: icon_create,
    delete_tinted: icon_delete_tinted,
    delete: icon_delete,
    document: icon_document,
    download_cloud: icon_download_cloud,
    download: icon_download,
    edit_tinted: icon_edit_tinted,
    edit: icon_edit,
    external_link: icon_external_link,
    eye_off: icon_eye_off,
    eye_on: icon_eye_on,
    image_file: icon_image_file,
    logout: icon_logout,
    menu: icon_menu,
    next: icon_next,
    pdf: icon_pdf,
    search: icon_search,
    password: icon_password,
  },

  /** Imágenes sin categoría utilizadas en ocasiones específicas. */
  misc: {
    enlace_icon_vector: misc_enlace_icon_vector,
    enlace_logo_small_vector: misc_enlace_logo_small_vector,
    enlace_logo_vector: misc_enlace_logo_vector,
  },
};

export default IP;
