import styled, { css } from "styled-components";
import { TRANSLATE, parseCSS } from "scripts/FunctionsBundle";
import { useLoaderData, useParams } from "react-router";
import ShowTable from "@components/ShowTable";
import { GS } from "App";
import { Helmet } from "react-helmet";
import { LoaderData } from "@utils/ClassTypes";
import Breadcrumbs from "@components/Breadcrumbs";

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
// ShowTableScreen => Rename all instances to use (CTRL + SHIFT + L)
type ShowTableScreenProps = {} & _Base;
// #endregion

// #region ##################################################################################### COMPONENT
const _ShowTableScreen = (props: ShowTableScreenProps) => {
  const data = useLoaderData() as LoaderData;
  const { userid, employeeid } = useParams();

  let next: "users" | "studies" | "employees" = "users";
  if (userid) {
    if (GS.currentUser?.isOrganization) next = "employees";
    else next = "studies";
  }

  if (employeeid) next = "studies";

  // ---------------------------------------------------------------------- RETURN
  return (
    <div className={props.className + " screen"}>
      <Helmet>
        <title>{TRANSLATE[next] + " | ENLACE Lab"}</title>
      </Helmet>
      <Breadcrumbs />

      <ShowTable
        key={next}
        _type={next}
        _data={data.data as any}
        _userName={data.userName}
        _employeeName={data.employeeName}
        _studyName={data.studyName}
      />
    </div>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const ShowTableScreen = styled(_ShowTableScreen).attrs(
  (props: ShowTableScreenProps): ShowTableScreenProps => {
    return { ...props };
  }
)<ShowTableScreenProps>`
  ${(props) => css`
    // Ingresa aquí todos los estilos.
    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default ShowTableScreen;
// #endregion
