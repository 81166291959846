type _AlMsg = import("@components/AlertMessage").AlertMessageProps;

// #region ##################################################################################### ESSENTIALS
// ---------------------------------------------------------------------- ONLY KEYS
/**
 * Selecciona todas las propiedades de un objeto, sin importar el tipo de dato.
 */
export type OnlyKeys<T> = { [P in keyof T]?: any };

// ---------------------------------------------------------------------- AUTO KEYS
/**
 * Selecciona todas las propiedades y permite agregar nuevas.
 */
export type AutoKeys<T> = OnlyKeys<T> & { [key: string]: any };

// ---------------------------------------------------------------------- _SETUP
/**
 * Clase extensible que coloca `props` automáticamente.
 * 1. {@link id} - ***readonly*** `string | null`.
 */
abstract class _setup {
  /**
   * `string | null` - ID del elemento.
   *
   * Si proviene de `Firestore`, entonces se refiere al ID del documento.
   *
   * Todos los elementos tienen un ID aún si no se necesita.
   * */
  readonly id: string | null = null;

  /**
   * Coloca todos los props automáticamente.
   * @param ini Objeto de donde obtener los datos iniciales.
   *
   * 1. Puede estar vacío y puede omitir algunos campos.
   * 2. Se toma el valor por defecto de las propiedades que no se incluyan.
   * 3. Si el tipo de dato de las propiedades no coincide, se ignora.
   * 4. Se pueden incluir propiedades de más.
   * 5. Si el campo actual es `null`, entonces acepta **cualquier valor** (`null <-- any`).
   * 6. Es **imposible colocar `null`** a un campo que ya exista (`any <-/- null`).
   */
  protected update(ini?: AutoKeys<this>) {
    if (ini === undefined) return;
    for (const key in this) {
      const that = ini[key];
      if (that === undefined || that === null) continue;
      if (
        this[key] === null ||
        (typeof that === typeof this[key] &&
          (typeof that !== "object" ||
            Object.getPrototypeOf(that) === Object.getPrototypeOf(this[key])))
      ) {
        this[key as string] = that;
      }
    }
  }

  /**
   * Coloca todos los props automáticamente.
   * @param ini Objeto de donde obtener los datos iniciales.
   *
   * 1. Puede estar vacío y puede omitir algunos campos.
   * 2. Se toma el valor por defecto de las propiedades que no se incluyan.
   * 3. Si el tipo de dato de las propiedades no coincide, se ignora.
   * 4. Se pueden incluir propiedades de más.
   * 5. Si el campo actual es `null`, entonces acepta **cualquier valor** (`null <-- any`).
   * 6. Es **imposible colocar `null`** a un campo que ya exista (`any <-/- null`).
   */
  constructor(ini?: AutoKeys<_setup>) {
    this.update(ini);
  }
}

// ---------------------------------------------------------------------- _BASE
/**
 * Provides basic prop structure for functional components
 */
export type _Base = {
  /** Overrides any other `className` passed. */
  className?: string;
  /** Overrides any other `style` passed. */
  _style?: import("react").CSSProperties;
};
// #endregion

// #region ##################################################################################### GLOBAL STATE
/**
 * Modelo para la variable global a utilizar en todo el sistema. Esta se creó con el propósito de minimizar las llamadas
 * a la base de datos (Firebase) y acceder a la información relevante más fácilmente.
 *
 * 1. {@link currentUser} - `User | null`.
 * 1. {@link people} - `Person[]`.
 * 1. {@link studies} - `Study[]`.
 * 1. {@link cache} - `any`.
 * 1. {@link refresh} - `() => void`.
 * 1. {@link firstTime} - `boolean`.
 * 1. {@link alert} - `AlertMessageProps`.
 * 1. {@link alertRef} - `() => void`.
 * 1. {@link setAlert} - ***readonly*** `() => void`.
 */
export class Global {
  /** `User | null` - Representa el usuario con la sesión iniciada actualmente. También describe sus permisos. */
  currentUser: User | null = null;
  /** `any` - Guarda cualquier clase de información, puede estar vacío y no es confiable. */
  cache: any = {};
  /** `() => void` - Representa una función para refrescar toda la aplicación desde la raíz. */
  refresh: () => void = () => {};
  /** `boolean` - Representa si es la primera vez que carga la aplicación desde el inicio. */
  firstTime: boolean = true;
  /**
   * `AlertMessageProps` - Parámetros para accionar el `AlertMessage`:
   *
   * 1. **`_type?`**: `"error"` | `"warning"` | `"success"` | `"informative"`; Color de la alerta, default: `"success"`.
   * 2. **`_message?`**: `string`; Mensaje de alerta a mostrar. Si no hay nada no se muestra.
   * 3. **`_timer?`**: `number`; Tiempo de espera (ms) para ocultar el mensaje automáticamente, default: `5000`.
   * 4. **`_hideButton?`**: `boolean`; Indica si es que dispondrá de un botón para ocultar el mensaje o no, default: `true`.
   */
  alert: _AlMsg = {};
  /** `() => void` - Función para accionar manualmente las alertas. */
  alertRef: () => void = () => {
    console.log((this.alert._type || "Alerta") + ":\n" + this.alert._message);
  };
  /** Actualiza las alertas y las ejecuta `alert = param` + `alertRef()`. */
  readonly setAlert = (p: _AlMsg) => {
    this.alert = p;
    this.alertRef();
  };
}
// #endregion

// #region ##################################################################################### DATABASE MODELS
// ---------------------------------------------------------------------- PERSON
/**
 * Representa a una persona de la base de datos. No contiene mucha info, pero contiene todos sus estudios.
 * 1. {@link id} - ***readonly*** `string | null`.
 * 1. {@link name} - `string`.
 * 1. {@link email} - `string`.
 * 1. {@link phone} - `string`.
 * 1. {@link studies} - `Study[]`.
 */
export class Person extends _setup {
  /** `string` - Representa el nombre del usuario. */
  name: string = "";
  /** `string` - Representa el correo electrónico del usuario. */
  email: string = "";
  /** `string` - Representa el número de teléfono del usuario. */
  phone: string = "";
  /** `Study[] | null` - Representa todos los estudios realizados por el usuario, o `null` si no tiene. */
  studies: Study[] | null = null;

  /**
   * Coloca todos los props automáticamente.
   * @param ini Objeto de donde obtener los datos iniciales.
   *
   * 1. Puede estar vacío y puede omitir algunos campos.
   * 2. Se toma el valor por defecto de las propiedades que no se incluyan.
   * 3. Si el tipo de dato de las propiedades no coincide, se ignora.
   * 4. Se pueden incluir propiedades de más.
   */
  constructor(ini?: AutoKeys<Person>) {
    super(ini);
    this.update(ini);
  }
}

// ---------------------------------------------------------------------- USER
/**
 * Representa a un usuario de la base de datos.
 *
 * A diferencia de las personas, los usuarios son quienes pagaron por los estudios, y pueden
 * tener subordinados (como si fueran empleados o hijos).
 *
 * 1. {@link id} - ***readonly*** `string | null`.
 * 1. {@link name} - `string`.
 * 1. {@link email} - `string`.
 * 1. {@link phone} - `string`.
 * 1. {@link studies} - `Study[]`.
 * 1. {@link isOrganization} - `boolean`.
 * 1. {@link people} - `Person[]`.
 */
export class User extends Person {
  /** `boolean` - Representa si es una organización, o no. */
  isOrganization: boolean = false;
  /** `Person[] | null` - Representa a todos los subordinados de este usuario, o `null` si no tiene. */
  people: Person[] | null = null;

  /**
   * Coloca todos los props automáticamente.
   * @param ini Objeto de donde obtener los datos iniciales.
   *
   * 1. Puede estar vacío y puede omitir algunos campos.
   * 2. Se toma el valor por defecto de las propiedades que no se incluyan.
   * 3. Si el tipo de dato de las propiedades no coincide, se ignora.
   * 4. Se pueden incluir propiedades de más.
   */
  constructor(ini?: AutoKeys<User>) {
    super(ini);
    this.update(ini);
  }
}

// ---------------------------------------------------------------------- STUDY
/**
 * Representa un estudio/análisis de alguna persona.
 *
 * Contiene varios archivos con URLs.
 *
 * 1. {@link id} - ***readonly*** `string | null`.
 * 1. {@link name} - `string`.
 * 1. {@link creationDate} - `Date`.
 * 1. {@link updateDate} - `Date | null`.
 * 1. {@link files} - `string[]`.
 */
export class Study extends _setup {
  /** `string` - Nombre del análisis realizado. */
  name: string = "Nuevo análisis";
  /** `Date` - Fecha de creación del estudio (default a hoy). */
  creationDate: Date = new Date();
  /** `Date | null` - Fecha de la última modificación de los archivos. */
  updateDate: Date | null = null;
  /** `string[]` - Representa una lista de URLs para ver los archivos. */
  files: string[] = [];

  /**
   * Coloca todos los props automáticamente.
   * @param ini Objeto de donde obtener los datos iniciales.
   *
   * 1. Puede estar vacío y puede omitir algunos campos.
   * 2. Se toma el valor por defecto de las propiedades que no se incluyan.
   * 3. Si el tipo de dato de las propiedades no coincide, se ignora.
   * 4. Se pueden incluir propiedades de más.
   */
  constructor(ini?: AutoKeys<Study>) {
    super(ini);
    this.update(ini);
  }
}
// #endregion

// #region ##################################################################################### MISCELLANEOUS
// ---------------------------------------------------------------------- LOADER DATA MODEL
export type LoaderData = {
  data: Person[] | User[] | Study[];
  userName: string;
  employeeName: string;
  studyName: string;
};
// #endregion
