// #region ##################################################################################### IMPORTS
// ---------------------------------------------------------------------- NORMAL IMPORTS
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { useRefresh, userSession } from "scripts/FunctionsBundle";
import * as _T from "@utils/ClassTypes";
import { initializeApp, deleteApp } from "firebase/app";
import * as FS from "firebase/firestore";
import * as ST from "firebase/storage";
import * as AO from "firebase/auth";
import LoginScreen from "@screens/LoginScreen";
import AnalysisReviewScreen from "@screens/AnalysisReviewScreen";
import { useEffect } from "react";
import HomeScreen from "@screens/HomeScreen";
import ShowTableScreen from "@screens/ShowTableScreen";

// ---------------------------------------------------------------------- TYPESCRIPT IMPORTS
type _LoaderFunctionArgs = import("react-router-dom").LoaderFunctionArgs;
// type _ActionFunctionArgs = import("react-router-dom").ActionFunctionArgs;
// #endregion

// #region ##################################################################################### FIREBASE INIT
export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
});
const db = FS.getFirestore(firebaseApp); // FIRESTORE (data db)
export const auth = AO.getAuth(firebaseApp); // AUTHETICATION (users db)
AO.onAuthStateChanged(auth, getCurrentUserInfo);
const store = ST.getStorage(firebaseApp); // STORAGE (files db)
auth.setPersistence(AO.browserLocalPersistence);
const adminids = [
  "KuDBiUTyhyQhpi6ZiCjeINnD6hq2",
  "HWxLBjiBzpbZkQIGRpgPv4WRTKv1",
];
// #endregion

// #region ##################################################################################### FIRESTORE
// ---------------------------------------------------------------------- FS PATH PROPS
/**
 * Props para usarse con {@link FSPath}.
 */
export type FSPathProps =
  | {
      subcoll: "studies";
      userID?: string;
      personID?: string;
    }
  | {
      subcoll: "people";
      userID?: string;
    }
  | "users";

// ---------------------------------------------------------------------- FS PATH
/**
 * Función auxiliar para crear un `path` válido con el cual acceder a `Firestore`.
 *
 * **Si queremos acceder a la información normal del `currentUser` no se aplican parámetros `req = undefined`.**
 * @param req Parámetros para poder moverse entre las sub-colecciones. Si no establecemos `userID` se toma el `currentUser.id`.
 * @returns Un `string` separado por slash-es que conduce a una colección de `Firestore`.
 */
function FSPath(req: FSPathProps) {
  if (req === "users" || !auth.currentUser?.uid) return "users";

  req.userID = req.userID || auth.currentUser.uid;
  let pth = `users/${req.userID}/`;
  if (req.subcoll === "studies" && req.personID) {
    pth += `people/${req.personID}/`;
  }

  pth += req.subcoll;

  return pth;
}

// ---------------------------------------------------------------------- FS ACTION
/**
 * Función con diversas utilidades para leer y manipular información dentro de `Firestore` (FS) rápidamente.
 * @param action Tipo de acción a realizar:
 *  1. `"read"`: Lee información de un documento en concreto a través de su ID.
 *  2. `"getall"`: Similar a `"read"`, pero con todos los documentos de la colección indicada.
 *  3. `"add"`: Guarda un nuevo registro dentro de la colección indicada con un ID aleatorio.
 *  4. `"insert"`: Reemplaza toda la info de un registro en concreto a través de su ID (si no existe, se crea).
 *  5. `"update"`: Modifica solamente los datos indicados de un registro en concreto a través de su ID (si no existe, marca error).
 *  6. `"delete"`: Elimina un registro a través de su ID (**DISABLED**).
 * @param options Información adicional para las subcolecciones del usuario, si no establecemos nada se busca en `users`.
 *  1. `subcoll`: Para saber si estamos entrando a `people` o `studies` del usuario.
 *  2. `personID`: Para buscar estudios de una persona del usuario. Independientemente de la `subcoll` establecida.
 *  3. `userID`: Para saber desde qué usuario estamos leyendo la subcolección, default: `currentUser.id`.
 * @param data Información con la cual trabajar. Debe contener al menos `{ id: "" }`.
 * @returns `null` siempre que la operación falle. `object` en caso de leer, o el mismo param `data` de otra manera.
 */
export function FSAction(
  action: "read" | "getall" | "add" | "insert" | "update" | "delete",
  options: FSPathProps,
  data: { [x: string]: any } & { id: string | null }
) {
  return new Promise<object | null>(async (resolve) => {
    // -------------------------------------------------- INSUFFICIENT DATA
    if (!auth.currentUser?.uid || data.id === null) {
      GS.setAlert({
        _type: "error",
        _message: !auth.currentUser
          ? "Es necesario iniciar sesión para realizar esta acción."
          : "Hace falta ingresar un ID de búsqueda válido.",
      });
      return resolve(null);
    }

    // -------------------------------------------------- INIT DATA
    /** `string` - ID del documento que queremos modificar. */
    const docID = data.id;
    /** `Collection` - Colección de FS donde está el documento a manipular. */
    const colref = FS.collection(db, FSPath(options));
    /** `any` - Información a guardar en la base de datos. */
    const newdata =
      action === "delete" ? {} : (parseToFS(data) as { [x: string]: any });

    // -------------------------------------------------- QUICK ERROR HANDLE
    function handleError(e: any) {
      GS.setAlert({
        _type: "error",
        _message: "Algo salió mal... revise su conexión e inténtelo de nuevo.",
      });
      console.error(e);
      resolve(null);
      return null;
    }

    // -------------------------------------------------- ACTIONS
    // ============================== READ ONCE
    if (action === "read") {
      const docref = FS.doc(colref, docID);
      const snap = await FS.getDoc(docref).catch(handleError);
      if (!snap || !snap.exists()) return resolve(null);

      resolve({ ...parseToFS(snap.data()), id: snap.id });
    }

    // ============================== READ ALL
    if (action === "getall") {
      const snap = await FS.getDocs(colref).catch(handleError);
      if (!snap) return resolve(null);

      const resp: any[] = [];
      snap.forEach((it) => resp.push({ ...parseToFS(it.data()), id: it.id }));
      resolve(resp);
    }

    // ============================== ADD
    if (action === "add") {
      const docref = await FS.addDoc(colref, newdata).catch(handleError);
      if (!docref) return resolve(null);

      resolve({ ...newdata, id: docref.id });
    }

    // ============================== INSERT
    if (action === "insert") {
      const docref = FS.doc(colref, docID);
      await FS.setDoc(docref, newdata).catch(handleError);
      resolve({ ...newdata, id: docref.id });
    }

    // ============================== UPDATE
    if (action === "update") {
      const docref = FS.doc(colref, docID);
      const temp = { ...newdata };
      if (data.files !== undefined) temp.files = data.files;
      await FS.updateDoc(docref, temp).catch(handleError);
      resolve({ ...temp, id: docref.id });
    }

    // ============================== DELETE (enabled)
    if (action === "delete") {
      const docref = FS.doc(colref, docID);
      await FS.deleteDoc(docref).catch(handleError);
      resolve({ ...newdata, id: docref.id });
    }

    resolve(null); // QUIT
  });
}

// ---------------------------------------------------------------------- PARSE TO FS
/**
 * Remueve las propiedades de `_specifics`(legacy), `update` y `id`,
 * y convierte todos los `Date` en `Timestamp` de Firebase de momento.
 * Otras propiedades que sean funciones o `undefined` simplemente las omite.
 *
 * También remueve `people` y `studies` automáticamente (porque son sub-colecciones, no arrays).
 * @param obj Objeto a transformar.
 * @returns Un nuevo objeto para ingresar directamente a FS (puede contener mutaciones).
 */
export function parseToFS(obj: any) {
  if (!obj || typeof obj !== "object" || obj instanceof Date) return null;
  let newobj: any;

  // -------------------------------------------------- IS PRIMITIVE
  /**
   * Los tipos de datos primitivos son `number`, `string` y `boolean`.
   * @param el Variable a revisar.
   * @returns `boolean` - Revisa si el `el` es uno de los tipos de datos anteriores.
   */
  function isPrimitive(el: any) {
    return (
      typeof el === "boolean" ||
      typeof el === "number" ||
      typeof el === "string"
    );
  }

  // -------------------------------------------------- ELEMENT IS ARRAY
  if (Array.isArray(obj)) {
    newobj = [];

    for (const item of obj) {
      if (isPrimitive(item)) newobj.push(item);
      else if (typeof item === "function" || item === undefined) continue;
      else if (item instanceof Date) newobj.push(FS.Timestamp.fromDate(item));
      else if (item instanceof FS.Timestamp) newobj.push(item.toDate());
      else newobj.push(parseToFS(item));
    }
  } else {
    // -------------------------------------------------- ELEMENT IS OBJECT
    const { update, _specifics, id, people, studies, ...rest } = obj;
    newobj = {};

    for (const key in rest) {
      const item = rest[key];
      if (isPrimitive(item)) newobj[key] = item;
      else if (typeof item === "function" || item === undefined) continue;
      else if (item instanceof Date) newobj[key] = FS.Timestamp.fromDate(item);
      else if (item instanceof FS.Timestamp) newobj[key] = item.toDate();
      else newobj[key] = parseToFS(item);
    }
  }

  return newobj;
}
// #endregion

// #region ##################################################################################### AUTHENTICATION
// ---------------------------------------------------------------------- LOG IN USER
/**
 * Inicia una sesión de usuario para trabajar con `Firestore` (FS).
 *
 * Utiliza el método `signInWithEmailAndPassword` de Firebase Auth.
 * @param email Email para iniciar sesión.
 * @param pass Contraseña para inicar sesión con *Firebase* (**NO ES LA CONTRASEÑA DEL EMAIL**).
 * @param searchSession Indica si se buscará o no una sessión registrada de usuario (*Desactivado*).
 */
export async function LogIn(
  email: string,
  pass: string,
  searchSession: boolean = false
) {
  // -------------------------------------------------- INITIAL VALUES
  // En caso de que queramos buscar alguna sesión guardada...
  await LogOut(true); // primero limpiamos todo por si acaso...

  // -------------------------------------------------- SIGNIN
  /** Function shortcut. */
  const er = (e: any) => {
    GS.setAlert({
      _type: "error",
      _message: "Correo o contraseña incorrecta.",
    });
    return null;
  };
  return await AO.signInWithEmailAndPassword(auth, email, pass).catch(er);
}

// ---------------------------------------------------------------------- LOG OUT USER
/**
 * Eliminar toda información almacenada en `GS` {@link GS}.
 * @param resetOnly Indica si se reinicia la `app` también, o solo los parámetros de `GS`.
 */
export async function LogOut(resetOnly = false) {
  await auth.signOut();
  GS.currentUser = null;
  GS.cache = {};
  userSession(undefined, true);
  if (!resetOnly) GS.refresh();
}

// ---------------------------------------------------------------------- GET CURRENT USER INFO
/**
 * Verifica si es que hay algún usuario con la sesión iniciada y obtiene su información para actualizar
 * el GlobalState (`GS`).
 *
 * Esto siempre se realiza al inicio de la aplicación y cada vez que se cambia de usuario.
 */
async function getCurrentUserInfo(user: AO.User | null) {
  if (!user) return;

  if (GS.firstTime) {
    clearTimeout(GS.cache);
    GS.cache = null;
  }

  // -------------------------------------------------- READ USER INFO
  let data = await FSAction("read", "users", { id: user.uid });
  if (!data) {
    await LogOut(true);
    GS.setAlert({
      _type: "error",
      _message: "Ocurrió un error al intentar leer la sesión de usuario...",
    });
    return;
  }

  // -------------------------------------------------- SAVE USER INFO
  GS.currentUser = new _T.User({ ...data, id: user.uid });
  GS.setAlert({
    _type: "success",
    _message: `Ha iniciado sesión el usuario: ${GS.currentUser.name}!`,
  });
  GS.firstTime = false;
  GS.refresh();
  return;
}

// ---------------------------------------------------------------------- IS ADMIN
/** Indica si el usuario registrado en la sesión actual es admin o no. */
export function isAdmin(uid?: string) {
  return adminids.includes(uid || auth.currentUser?.uid || "");
}

// ---------------------------------------------------------------------- CREATE USER
/** Crea un nuevo usuario con correo y contraseña. */
export async function createUser(email: string, password: string) {
  // -------------------------------------------------- VERIFICATIONS
  if (!email || !password || !isAdmin()) {
    GS.setAlert({
      _type: "error",
      _message: "No fue posible crear un nuevo usuario",
    });
    return null;
  }

  // -------------------------------------------------- SHORTCUT
  function handleError(e: any) {
    GS.setAlert({
      _type: "error",
      _message: "Ha ocurrido un error al intentar crear el usuario",
    });
    console.error(e);
    return null;
  }

  // -------------------------------------------------- SECONDARY APP
  const secondaryApp = initializeApp(
    {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
      appId: process.env.REACT_APP_FIREBASE_APPID,
    },
    "secondary"
  );
  const auth2 = AO.getAuth(secondaryApp);
  const user = await AO.createUserWithEmailAndPassword(
    auth2,
    email,
    password
  ).catch(handleError);
  await auth2.signOut().catch(handleError);
  await deleteApp(secondaryApp).catch(handleError);
  return user;
}

// ---------------------------------------------------------------------- RESET PASSWORD
/** Envía un correo de restablecimiento de contraseña al email ingresado. */
export async function resetPassword(email: string) {
  try {
    await AO.sendPasswordResetEmail(auth, email);
    return true;
  } catch (e) {
    GS.setAlert({
      _type: "error",
      _message: "Ha ocurrido un error al intentar enviar el correo.",
    });
    console.error(e);
    return false;
  }
}
// #endregion

// #region ##################################################################################### CLOUD STORAGE
// ---------------------------------------------------------------------- ST PATH PROPS
/**
 * Props para usarse con {@link STPath}.
 */
export type STPathProps = {
  studyID: string;
  personID?: string;
  userID?: string;
};

// ---------------------------------------------------------------------- FILES DATA TYPE
/** `Array` que contiene un `url` y un `fileName` de cada archivo extraído de `Cloud Storage`. */
type FilesData = { url: string; fileName: string }[];

// ---------------------------------------------------------------------- ST PATH
/**
 * Función auxiliar para crear un `path` válido con el cual acceder a `Cloud Storage`.
 * @param req Parámetros para poder moverse entre las capetas. Si no establecemos `userID` se toma el `currentUser.id`.
 * @returns Un `string` separado por slash-es que conduce a una carpeta de `Cloud Storage`.
 */
function STPath(req: STPathProps) {
  if (!auth.currentUser?.uid) return "";

  req.userID = req.userID || auth.currentUser.uid;
  if (req.personID)
    return `${req.userID}/people/${req.personID}/${req.studyID}/`;
  return `${req.userID}/studies/${req.studyID}/`;
}

// ---------------------------------------------------------------------- ST ACTION
/**
 * Función con diversas utilidades para leer y manipular archivos dentro de `Cloud Storage` (ST) rápidamente.
 * @param action Tipo de acción a realizar:
 *  1. `"read"`: Obtiene un archivo en concreto a través de `options.fileName`.
 *  2. `"getall"`: Similar a `"read"`, pero con todos los archivos de la carpeta.
 *  3. `"add"`: Guarda un nuevo archivo dentro de la carpeta indicada con un `options.fileName` aleatorio.
 *  4. `"insert"`: Reemplaza un archivo en concreto a través de su `options.fileName` (si no existe, se crea).
 *  5. `"update"`: Reemplaza un archivo en concreto a través de su `options.fileName` (si no existe, marca error).
 *  6. `"delete"`: Elimina un achivo a través de su `options.fileName` (**DISABLED**).
 * @param options Información adicional para las subcarpetas del usuario, si no establecemos nada se busca en `users`.
 *  1. `studyID`: Todos los archivos provienen de algún análisis, por lo que todos llevan `studyID`.
 *  2. `personID`: Para buscar estudios de una persona del usuario.
 *  3. `userID`: Para saber desde qué usuario estamos leyendo el análisis, default: `currentUser.id`.
 *  4. `fileName`: Para buscar un archivo en concreto con este mismo nombre.
 * @param file Archivo a guardar en la base de datos, siempre se le coloca un `fileName` aleatorio.
 * @returns `null` siempre que la operación falle. `string[]` con los URLs de descarga para cada archivo.
 */
export function STAction(
  action: "read" | "getall" | "add" | "insert" | "update" | "delete",
  options: STPathProps & { fileName: string },
  file?: File
) {
  return new Promise<FilesData | null>(async (resolve) => {
    // -------------------------------------------------- INSUFFICIENT DATA
    if (!auth.currentUser?.uid) {
      GS.setAlert({
        _type: "error",
        _message: "Es necesario iniciar sesión para realizar esta acción.",
      });
      return resolve(null);
    }

    // -------------------------------------------------- INIT DATA
    options.fileName = options.fileName || "fail"; // Failsafe
    /** `StorageReference` - Carpeta de ST donde está el archivo a manipular. */
    const folderref = ST.ref(store, STPath(options));

    // -------------------------------------------------- QUICK ERROR HANDLE
    function handleError(e: any) {
      GS.setAlert({
        _type: "error",
        _message: "Algo salió mal... revise su conexión e inténtelo de nuevo.",
      });
      console.error(e);
      resolve(null);
      return null;
    }

    // -------------------------------------------------- ACTIONS
    // ============================== READ ONCE
    if (action === "read") {
      const fileref = ST.ref(folderref, options.fileName);
      const url = await ST.getDownloadURL(fileref).catch(handleError);
      if (!url) return resolve(null);

      resolve([{ url, fileName: fileref.name }]);
    }

    // ============================== READ ALL
    if (action === "getall") {
      const allfiles = await ST.listAll(folderref).catch(handleError);
      if (!allfiles) return resolve(null);

      const toReturn: FilesData = [];
      allfiles.items.forEach(async (file) => {
        const url = await ST.getDownloadURL(file).catch(handleError);
        if (url) toReturn.push({ url, fileName: file.name });
      });

      resolve(toReturn);
    }

    // ============================== ADD & INSERT & UPDATE
    if (action === "add" || action === "insert" || action === "update") {
      if (!file) {
        GS.setAlert({
          _type: "error",
          _message: "Hace falta adjuntar un archivo para subir a la nube.",
        });
        return resolve(null);
      }

      const exIndex = file.name.lastIndexOf(".");

      const fileName =
        action === "add"
          ? (options.fileName || file.name.substring(0, exIndex)) +
            ";;" +
            window.crypto.randomUUID() +
            file.name.substring(exIndex)
          : options.fileName;
      const fileref = ST.ref(folderref, fileName);
      const snap = await ST.uploadBytes(fileref, file).catch(handleError);
      if (!snap) return resolve(null);
      const url = await ST.getDownloadURL(snap.ref).catch(handleError);
      if (!url) return resolve(null);

      resolve([{ url, fileName: snap.ref.name }]);
    }

    // ============================== DELETE (enabled)
    if (action === "delete") {
      const fileName = options.fileName;
      const fileref = ST.ref(folderref, fileName);
      await ST.deleteObject(fileref).catch(handleError);
      resolve([{ url: "", fileName }]);
    }

    resolve(null); // QUIT
  });
}
// #endregion

// #region ##################################################################################### GLOBAL STATE
/**
 * Objeto principal de estado global. Almacena toda la información de la base de datos relevante.
 */
export const GS = new _T.Global();
// #endregion

// #region ##################################################################################### FUNCIONES LOADERS
/** Verifica si el usuario actual tiene iniciada la sesión, de lo contrario regresa a login. */
// ---------------------------------------------------------------------- CHECK USER
async function checkUser(req: _LoaderFunctionArgs) {
  if (!auth.currentUser) {
    GS.setAlert({
      _message: "Inicie sesión para continuar",
      _type: "warning",
    });
    return redirect("/login");
  }
  return null;
}

// ---------------------------------------------------------------------- CHECK USER - ADMIN
/** Realiza una verificación del usuario actual para revisar si es tipo admin o usuario normal. */
async function loadUserData(req: _LoaderFunctionArgs) {
  // ============================== PREV CHECK
  const prev = await checkUser(req);
  if (prev) return prev;

  // -------------------------------------------------- PARAMS
  const { userid } = req.params;

  // -------------------------------------------------- CHECK - IF user check on user, or admin
  if (!auth.currentUser || (auth.currentUser.uid !== userid && !isAdmin())) {
    return redirect(`users/${auth.currentUser?.uid}`); // REDIRECT IF NOT ADMIN
  }

  // ============================== CHECK CACHED DATA
  if (userid && (!GS.currentUser || GS.currentUser.id !== userid)) {
    const data = await FSAction("read", "users", { id: userid });

    // NO DATA
    if (!data) {
      throw new Response("Not Found", {
        status: 404,
        statusText: `No existe el usuario '${userid}'.`,
      });
    }

    // SAVE DATA
    GS.currentUser = new _T.User(data);
  }

  // ============================== GET ALL USERS
  if (!userid && isAdmin()) {
    const data = await FSAction("getall", "users", { id: "" });

    // NO DATA
    if (!data) {
      throw new Response("Not Found", {
        status: 404,
        statusText: "No se han encontrado usuarios.",
      });
    }

    // RETURN DATA
    return {
      data,
      userName: "",
      employeeName: "",
      studyName: "",
    };
  }

  // ============================== DEFAULT RETURN
  return null;
}

// ---------------------------------------------------------------------- CHECK USER - DATA
/** Revisa si se ha encontrado la información necesitada. */
async function loadInformation(req: _LoaderFunctionArgs) {
  // ============================== PREV CHECK
  const prev = await loadUserData(req);
  if (prev) return prev;

  // -------------------------------------------------- PARAMS
  const { userid, employeeid } = req.params;
  if (!userid || !GS.currentUser) return redirect("/");

  // -------------------------------------------------- BUSCAR EMPLEADOS DE UN USUARIO
  if (GS.currentUser.isOrganization) {
    // ============================== CHECK CACHED DATA
    if (!GS.currentUser.people) {
      const data = await FSAction(
        "getall",
        { subcoll: "people", userID: userid },
        { id: "" }
      );

      // NO DATA
      if (!data) {
        throw new Response("Not Found", {
          status: 404,
          statusText: `El usuario '${GS.currentUser.id}' no tiene empleados.`,
        });
      }

      // SAVE DATA
      GS.currentUser.people = data as _T.Person[];
    }

    // ============================== RETURN IF NECESSARY
    if (!employeeid)
      return {
        data: GS.currentUser.people,
        userName: GS.currentUser.name,
        employeeName: "",
        studyName: "",
      };

    // -------------------------------------------------- BUSCAR ESTUDIOS DE UN EMPLEADO
    // ============================== FIND CACHED DATA
    const currentEmployee = GS.currentUser.people.find(
      (p) => p.id === employeeid
    );
    if (!currentEmployee) {
      throw new Response("Not Found", {
        status: 404,
        statusText: `No existe el empleado '${employeeid}'.`,
      });
    }

    // ============================== GET DATA
    if (!currentEmployee.studies) {
      const data = await FSAction(
        "getall",
        { subcoll: "studies", userID: userid, personID: employeeid },
        { id: "" }
      );

      // ============================== NO DATA
      if (!data) {
        throw new Response("Not Found", {
          status: 404,
          statusText: `No se han encontrado estudios del empleado '${employeeid}'.`,
        });
      }

      // ============================== DATA FOUND
      currentEmployee.studies = data as _T.Study[];
    }

    // ============================== RETURN
    return {
      data: currentEmployee.studies,
      userName: GS.currentUser.name,
      employeeName: currentEmployee.name,
      studyName: "",
    };
  }

  // ============================== IS NOT ORGANIZATION, HAS NOT EMPLOYEES
  if (employeeid) {
    GS.setAlert({
      _message: "Este usuario no es empresarial.",
      _type: "warning",
    });
    return redirect(`users/${GS.currentUser?.id}`);
  }

  // -------------------------------------------------- BUSCAR ESTUDIOS DE UN USUARIO
  if (!GS.currentUser.studies) {
    const data = await FSAction(
      "getall",
      { subcoll: "studies", userID: userid },
      { id: "" }
    );

    // NO DATA
    if (!data) {
      throw new Response("Not Found", {
        status: 404,
        statusText: "Este usuario no tiene análisis.",
      });
    }

    // SAVE DATA
    GS.currentUser.studies = data as _T.Study[];
  }

  // ============================== DEFAULT RETURN
  return {
    data: GS.currentUser.studies,
    userName: GS.currentUser.name,
    employeeName: "",
    studyName: "",
  };
}

// ---------------------------------------------------------------------- CHECK FILES
/** Busca todos los archivos del Cloud Storage. */
async function checkFiles(req: _LoaderFunctionArgs) {
  // ============================== PREV CHECK
  const prev = await loadInformation(req);
  if (prev !== null && prev instanceof Response) return prev;
  const aux = prev as _T.LoaderData;

  // -------------------------------------------------- PARAMS
  const { userid, studyid } = req.params;
  if (!userid || !studyid || !GS.currentUser) return redirect("/");

  // -------------------------------------------------- CHECK
  const currentStudy = (aux.data as _T.Study[]).find((s) => s.id === studyid);

  // NO DATA
  if (!currentStudy) {
    throw new Response("Not Found", {
      status: 404,
      statusText: `No se ha encontrado el análisis '${studyid}'`,
    });
  }

  // ============================== DEFAULT RETURN
  return {
    data: currentStudy,
    userName: aux.userName,
    employeeName: aux.employeeName,
    studyName: currentStudy.name,
  };
}
// #endregion

// #region ##################################################################################### MAIN APPLICATION
function App() {
  // ---------------------------------------------------------------------- GLOBAL STATE
  const [refresh] = useRefresh();
  GS.refresh = refresh;

  // ---------------------------------------------------------------------- FIRST TIME
  useEffect(() => {
    const a = (GS.cache = setTimeout(() => {
      GS.firstTime = false;
      GS.refresh();
    }, 1000));

    return () => {
      GS.firstTime = false;
      clearTimeout(a);
    };
  }, []);

  // ---------------------------------------------------------------------- RETURN
  return (
    <RouterProvider
      router={createBrowserRouter([
        // -------------------------------------------------- APPLICATION ROOT
        {
          path: "/",
          errorElement: <HomeScreen isNotFound />,
          element: <HomeScreen />,
          children: [
            // -------------------------------------------------- ADMIN ONLY
            {
              index: true,
              loader: loadUserData,
              element: <ShowTableScreen />,
            },
            // -------------------------------------------------- LOGIN
            {
              path: "login",
              element: <LoginScreen />,
            },
            // -------------------------------------------------- USERS
            {
              path: "users/:userid",
              loader: loadUserData,
              children: [
                // ============================== SELECCIONAR TODOS LOS ANÁLISIS/EMPLEADOS
                {
                  index: true,
                  loader: loadInformation,
                  element: <ShowTableScreen />,
                },
                // ============================== UN ANÁLISIS EN CONCRETO
                {
                  path: "studies/:studyid",
                  loader: checkFiles,
                  element: <AnalysisReviewScreen />,
                },
                // ============================== TODOS LOS ANÁLISIS DEUN EMPLEADO EN CONCRETO
                {
                  path: "employees/:employeeid",
                  loader: loadInformation,
                  element: <ShowTableScreen />,
                },
                // ============================== UN ANÁLISIS EN CONCRETO, DE UN EMPLEADO EN CONCRETO
                {
                  path: "employees/:employeeid/studies/:studyid",
                  loader: checkFiles,
                  element: <AnalysisReviewScreen />,
                },
              ],
            },
          ],
        },
        // -------------------------------------------------- NOT FOUND SCREEN
        {
          path: "*",
          element: <HomeScreen isNotFound />,
        },
      ])}
    />
  );
}
// #endregion

// #region ##################################################################################### EXPORTS
export default App;
// #endregion
