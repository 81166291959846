import styled, { css } from "styled-components";
import {
  TRANSLATE,
  parseCSS,
  parseDate,
  parseID,
  useRefresh,
} from "scripts/FunctionsBundle";
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import * as _T from "@utils/ClassTypes";
import IP from "@utils/ImageProvider";
import { GS, isAdmin } from "App";
import Input from "./Input";
import ModalCRUD, { ModalCRUDProps } from "./ModalCRUD";
import ModalPass, { ModalPassProps } from "./ModalPass";

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
type _Info = _T.User & _T.Person & _T.Study;
// ShowTable => Rename all instances to use
export type ShowTableProps = {
  _type: "users" | "employees" | "studies";
  _data: _Info[];
  _userName?: string;
  _employeeName?: string;
  _studyName?: string;
} & _Base;
// #endregion

// #region ##################################################################################### COMPONENT
const _ShowTable = (props: ShowTableProps) => {
  const [LS] = useState<{ name: string; date: Date | null }>({
    name: "",
    date: null,
  });
  const [refresh] = useRefresh();
  const navigator = useNavigate();
  const [sortedData, setSortedData] = useState<_Info[]>([]);
  const [modal, setModal] = useState<ModalCRUDProps | null>(null);
  const [modalPass, setModalPass] = useState<ModalPassProps | null>(null);
  const { userid, employeeid } = useParams();

  // ---------------------------------------------------------------------- STOP PROPAGATION
  function stop(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  // ---------------------------------------------------------------------- HANDLE MODAL
  const handleModal = useCallback(
    (type: ModalCRUDProps["_action"], el: _Info) => {
      setModal({
        _action: type,
        _origin: el,
        _type: props._type,
        _cleanse: true,
        _FSAction:
          props._type === "employees"
            ? {
                subcoll: "people",
                userID: userid,
              }
            : props._type === "studies"
            ? {
                subcoll: "studies",
                userID: userid,
                personID: employeeid,
              }
            : "users",
        _onClick: () => setModal(null),
      });
    },
    [employeeid, props._type, userid]
  );

  // ---------------------------------------------------------------------- HANDLE MODAL PASS
  const handleModalPass = useCallback((email: string | null) => {
    setModalPass({
      _email: email,
      _onClick: () => setModalPass(null),
    });
  }, []);

  // ---------------------------------------------------------------------- USE EFFECT - SORT
  const updateSorting = useCallback(() => {
    const a = [...props._data].sort((a, b) => {
      if (props._type === "studies") {
        const aa = a.updateDate || a.creationDate;
        const bb = b.updateDate || b.creationDate;
        return aa > bb ? -1 : aa < bb ? 1 : 0;
      }

      const aa = parseID(a.name);
      const bb = parseID(b.name);
      return aa > bb ? 1 : aa < bb ? -1 : 0;
    });

    setSortedData(a);
  }, [props._data, props._type]);

  useEffect(updateSorting, [updateSorting]);

  // ---------------------------------------------------------------------- USE CALLBACK - FILTER
  const getFilter = useCallback(() => {
    // -------------------------------------------------- HEADERS
    const headers = (
      <thead>
        <tr>
          {props._type === "studies" ? (
            <>
              <th>Última modificación</th>
              <th>Nombre del análisis</th>
              <th>No. de archivos</th>
            </>
          ) : props._type === "users" ? (
            <>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Teléfono</th>
              <th>Empresarial?</th>
            </>
          ) : (
            <>
              <th>Nombre</th>
              <th>Correo de la empresa</th>
              <th>Tel. de la empresa</th>
            </>
          )}

          {isAdmin() && <th>Opciones</th>}
        </tr>
      </thead>
    );

    // -------------------------------------------------- AUX DATA
    let prev = "";
    const returnData: React.JSX.Element[] = [];
    let aux: React.JSX.Element[] = [];
    function scut() {
      if (!prev)
        return (
          <h2 key={props._type} className="no-results">
            No hay información a mostrar
          </h2>
        );

      return (
        <div key={prev}>
          <h2
            className="no-selectable"
            title="Pulsa para contraer"
            onClick={(e) =>
              (e.target as HTMLElement).classList.toggle("collapsed")
            }
          >
            {props._type === "studies" ? parseDate(prev + "-10", false) : prev}
          </h2>
          <div className="subtable">
            <table>
              {headers}
              <tbody>{aux}</tbody>
            </table>
          </div>
        </div>
      );
    }

    // -------------------------------------------------- FOR EACH
    sortedData.forEach((d, i) => {
      const tdate = d.updateDate || d.creationDate || new Date(0);
      // ============================== FILTER
      if (props._type === "studies" && LS.date && LS.date > tdate) return;
      const n = parseID(d.name);
      const s = parseID(LS.name);
      if (!n.includes(s)) return;

      // ============================== RETURN
      const to = `${props._type}/${d.id}`;
      const rt = (
        <tr key={i} onClick={() => navigator(to)}>
          {props._type === "studies" ? (
            <>
              <td>{(d.updateDate || d.creationDate).toLocaleDateString()}</td>
              <td>{d.name}</td>
              <td>{d.files.length}</td>
            </>
          ) : props._type === "users" ? (
            <>
              <td>{d.name}</td>
              <td>{d.email}</td>
              <td>{d.phone || "N/A"}</td>
              <td>{d.isOrganization ? "Sí" : "No"}</td>
            </>
          ) : (
            <>
              <td>{d.name}</td>
              <td>{GS.currentUser?.email || "N/A"}</td>
              <td>{GS.currentUser?.phone || "N/A"}</td>
            </>
          )}

          {isAdmin() && (
            <td className="admin-controls" onClick={stop}>
              {props._type === "users" && (
                <img
                  src={IP.icon.password}
                  alt="icon edit"
                  onClick={(e) => {
                    stop(e);
                    handleModalPass(d.email);
                  }}
                />
              )}
              <img
                src={IP.icon.edit_tinted}
                alt="icon edit"
                onClick={(e) => {
                  stop(e);
                  handleModal("update", d);
                }}
              />
              <img
                src={IP.icon.delete_tinted}
                alt="icon delete"
                onClick={(e) => {
                  stop(e);
                  handleModal("delete", d);
                }}
              />
            </td>
          )}
        </tr>
      );

      // ============================== PREV CHANGE
      const x =
        props._type === "studies"
          ? (d.updateDate || d.creationDate).toISOString().substring(0, 7)
          : n[0].toUpperCase();
      if (
        (props._type !== "studies" && prev < x) ||
        (props._type === "studies" && (prev > x || !prev))
      ) {
        if (!prev) {
          // SAVE CURRENT
          prev = x;
          aux.push(rt);
          return;
        }

        // SAVE PREV
        returnData.push(scut());

        prev = x;
        aux = [];
      }

      // SAVE CURRENT
      aux.push(rt);
    });

    // SAVE LAST PREV
    returnData.push(scut());

    return returnData;
  }, [
    LS.date,
    LS.name,
    handleModal,
    navigator,
    props._type,
    sortedData,
    handleModalPass,
  ]);

  // ---------------------------------------------------------------------- RETURN
  return (
    <div className={props.className + " show-table"}>
      {/* -------------------------------------------------- TITLE */}
      <h1>{TRANSLATE[props._type]}</h1>
      {/* -------------------------------------------------- CONTROLS CONTAINER */}
      <div className="controls-container">
        <Input
          _store={LS}
          _store_var={"name"}
          _label={""}
          _placeholder={"Buscar"}
          _width={"max"}
          _type={"search"}
          _className={"search-input " + props._type}
        >
          <div className="wrapper icon-search bg-img" />
        </Input>

        {props._type === "studies" && (
          <Input
            _store={LS}
            _store_var={"date"}
            _label={""}
            _width={"max"}
            _type={"date"}
          />
        )}

        <button className="logout" onClick={refresh}>
          Buscar
        </button>

        {isAdmin() && (
          <ModalCRUD
            _action={"create"}
            _type={props._type}
            _cleanse
            _FSAction={
              props._type === "employees"
                ? {
                    subcoll: "people",
                    userID: userid,
                  }
                : props._type === "studies"
                ? {
                    subcoll: "studies",
                    userID: userid,
                    personID: employeeid,
                  }
                : "users"
            }
          >
            <span className="txt">Añadir {TRANSLATE[props._type]}</span>
            <img className="alt-resp" src={IP.icon.create} alt="create" />
          </ModalCRUD>
        )}
      </div>
      {/* -------------------------------------------------- TABLE CONTAINER */}
      <div className="table-container">{getFilter()}</div>

      {isAdmin() && modal && (
        <ModalCRUD {...modal} _afterChange={updateSorting} />
      )}

      {isAdmin() && modalPass && <ModalPass {...modalPass} />}
    </div>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const ShowTable = styled(_ShowTable).attrs((props: _Base): _Base => {
  return props;
})<ShowTableProps>`
  ${(props) => css`
    .icon-search {
      background-image: url(${IP.icon.search});
    }
    input[type="date"] {
      appearance: initial;
      background-color: white;
      background-image: url(${IP.icon.calendar});
      background-position: calc(100% - 0.5em) 50%;
      background-repeat: no-repeat;
      background-size: 1.5em;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }
    input[type="date"]::-webkit-inner-spin-button {
      display: none;
    }

    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default ShowTable;
// #endregion
