import styled, { css } from "styled-components";
import { parseCSS, parseID, useRefresh } from "scripts/FunctionsBundle";
import { useState } from "react";
import Input, { InputProps } from "./Input";
import { Fragment } from "react";
import IP from "@utils/ImageProvider";

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
// CRUD => Rename all instances to use (CTRL + SHIFT + L)
type CRUDProps = {
  /** Título del CRUD a crear, por ejemplo "precios" u "opciones". */
  _title?: string;
  /** Texto a mostrar en el botón de crear nuevo elemento. */
  _create_button?: string;
  /** Texto a mostrar en el botón de eliminar un elemento. */
  _delete_button?: string;

  /** Arreglo de donde modificar la información */
  _store: Array<any>;
  /** Valor a ingresar cada vez que pulsemos "nuevo elemento". */
  _store_new: any;
  /** En caso de que vayamos a manipular un objeto más grande, aquí seleccionamos qué inputs queremos mostrar. */
  _complex?: Omit<InputProps, "_store">[];
  /** Modificadores para el Input */
  _input_props?: Omit<InputProps, "_store" | "_store_var">;
  /** En caso de que el CRUD sea de solo lectura. */
  _disabled?: boolean;
} & _Base;
// #endregion

// #region ##################################################################################### COMPONENT
const _CRUD = (props: CRUDProps) => {
  // ---------------------------------------------------------------------- HOOK STATES
  const [LS] = useState(props._store || []);
  const [refresh, volkey] = useRefresh();
  // ---------------------------------------------------------------------- RETURN
  return (
    <>
      <label>{props._title}</label>

      <ol className={props.className + " crud-list"} key={volkey}>
        {LS.map((value, i) => {
          return props._complex ? (
            <Fragment key={volkey + "-" + i}>
              <button
                className={"danger delete-button"}
                type={"button"}
                disabled={props._disabled}
                onClick={() => {
                  LS.splice(i, 1);
                  refresh();
                }}
              >
                {props._delete_button}
              </button>

              <div className={"sub-fieldset"}>
                {props._complex.map((ppp, ii) => (
                  <Input
                    key={volkey + "-" + i + "-" + ii}
                    _store={LS[i]}
                    _disabled={props._disabled}
                    {...ppp}
                  />
                ))}
              </div>
            </Fragment>
          ) : (
            <li key={volkey + "-" + i}>
              <div className="crud-list-item">
                <Input
                  _name={parseID(props._title || "", true)}
                  _store={LS}
                  _store_var={i + ""}
                  _label={""}
                  _required={"No se admiten valores vacíos."}
                  _disabled={props._disabled}
                  {...props._input_props}
                  _onChange={(value, el) => {
                    let res = props._input_props?._onChange?.(value, el);
                    const errmsg = "Este valor ya existe en el listado.";
                    if (
                      !LS.includes(value) ||
                      (LS.indexOf(value) === i && LS.lastIndexOf(value) === i)
                    ) {
                    } else res = res ? res + " " + errmsg : errmsg;
                    return res;
                  }}
                  _withWrapper={false}
                  _style={{
                    flexGrow: 1,
                  }}
                >
                  <button
                    className={"danger"}
                    type={"button"}
                    disabled={props._disabled}
                    onClick={() => {
                      LS.splice(i, 1);
                      refresh();
                    }}
                  >
                    <span className="delete-label">{props._delete_button}</span>
                    <img
                      className="delete-label"
                      src={IP.icon.delete_tinted}
                      alt="delete"
                    />
                  </button>
                </Input>
              </div>
            </li>
          );
        })}

        <button
          className={"primary create-button"}
          type={"button"}
          disabled={props._disabled}
          onClick={() => {
            LS.push(
              typeof props._store_new === "string"
                ? (props._store_new ? props._store_new + " " : "") +
                    (LS.length + 1)
                : props._store_new === "function"
                ? props._store_new() || {}
                : structuredClone(props._store_new || {})
            );
            refresh();
          }}
        >
          {props._create_button}
        </button>
      </ol>
    </>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const CRUD = styled(_CRUD).attrs((props: CRUDProps): CRUDProps => {
  return {
    _title: "Lista de elementos:",
    _create_button: "Crear nuevo",
    _delete_button: "Eliminar",
    ...props,
  };
})<CRUDProps>`
  ${(props) => css`
    /* ######################### CREATE BUTTON */
    .create-button {
      grid-column: 2 / -1;
      width: max-content;
      margin: 0;
    }

    /* ######################### DELETE BUTTON */
    .delete-button {
      width: max-content;
      margin: 0;
    }

    /* ######################### ELEMENTS */
    .element {
      position: relative;
      z-index: 0;

      &::before {
        content: "";
        left: -3%;
        right: 100%;
        height: 100%;
        z-index: -1;

        border-left: 3px solid;
      }

      &::after {
        content: "";
        left: -3%;
        right: 100%;
        height: 50%;
        z-index: -1;

        border-bottom: 3px solid;
        border-left: 3px solid;
        border-radius: 0 1rem;
      }

      &.top::after {
        height: 1rem;
      }

      &:last-child::before {
        height: 50%;
        border-radius: 0 1rem;
      }
    }

    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default CRUD;
// #endregion
