import styled, { css } from "styled-components";
import { useLoaderData, useParams } from "react-router";
import * as _T from "@utils/ClassTypes";
import { isAdmin } from "App";
import ModalCRUD from "@components/ModalCRUD";
import {
  parseCSS,
  parseFileData,
  parseID,
  useRefresh,
} from "scripts/FunctionsBundle";
import { useState } from "react";
import IP from "@utils/ImageProvider";
import Input from "@components/Input";
import { Helmet } from "react-helmet";
import PDFViewer from "@components/PDFViewer";
import Breadcrumbs from "@components/Breadcrumbs";

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
// AnalysisReviewScreen => Rename all instances to use (CTRL + SHIFT + L)
type AnalysisReviewScreenProps = {} & _Base;
// #endregion

// #region ##################################################################################### COMPONENT
const _AnalysisReviewScreen = (props: AnalysisReviewScreenProps) => {
  const data = useLoaderData() as Omit<_T.LoaderData, "data"> & {
    data: _T.Study;
  };
  const { userid, studyid, employeeid } = useParams();
  const [LS] = useState({ name: "" });
  const [modal, setModal] = useState<{
    _action: "update" | "delete";
    _url: string;
  } | null>(null);
  const [refresh] = useRefresh();
  const [frame, setFrame] = useState<{
    _src: string;
    _title?: string;
  } | null>(null);

  // ---------------------------------------------------------------------- HANDLE BUTTON CLICK
  async function handleClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url: string
  ) {
    const test = parseFileData(url, "extension", true);
    // CANNOT OPEN IFRAME
    if (test === url || test !== "pdf") return;
    else event.preventDefault();

    setFrame({
      _src: url,
      _title: parseFileData(url, "name", true),
    });
  }

  // ---------------------------------------------------------------------- RETURN
  return (
    <div className={props.className + " screen show-table"}>
      <Helmet>
        <title>Detalles | ENLACE Lab</title>
      </Helmet>
      <Breadcrumbs />

      <h1>Detalles del Análisis</h1>

      {/* -------------------------------------------------- CONTROLS CONTAINER */}
      <div className="controls-container">
        <Input
          _store={LS}
          _store_var={"name"}
          _label={""}
          _placeholder={"Buscar"}
          _width={"max"}
          _type={"search"}
          _className={"search-input"}
        >
          <div className="wrapper icon-search bg-img" />
        </Input>

        <button className="logout" onClick={refresh}>
          Buscar
        </button>

        {isAdmin() && (
          <ModalCRUD
            _type="files"
            _action={"create"}
            _controls
            _originFile={""}
            _cleanse
            _STAction={{
              studyID: studyid || "err",
              userID: userid,
              personID: employeeid,
            }}
          >
            <span className="txt">Añadir archivos</span>
            <img className="alt-resp" src={IP.icon.create} alt="create" />
          </ModalCRUD>
        )}
      </div>

      {!data || !data.data?.files?.length ? (
        <h2 className="no-results">No hay resultados</h2>
      ) : (
        <div className="row">
          {data.data.files.map((fullUrl, index) => {
            const filename = parseFileData(fullUrl, "name", true);
            if (LS.name && !parseID(filename).includes(parseID(LS.name)))
              return null;

            const ext = parseFileData(fullUrl, "extension", true);
            let isrc =
              ext === fullUrl
                ? IP.icon.external_link
                : ext === "pdf"
                ? IP.icon.pdf
                : ext === "png" || ext === "jpg"
                ? IP.icon.image_file
                : IP.icon.document;

            return (
              <div key={index} className="file-card">
                <div className="header-container">
                  <img className="icon-pdf" src={isrc} alt="icon pdf" />
                  <span className="filename">{filename}</span>
                  <button
                    className="button-menu"
                    onClick={(e) => {
                      (e.target as HTMLButtonElement).classList.toggle(
                        "show-menu"
                      );
                    }}
                    onBlur={(e) => {
                      if (
                        !e.relatedTarget ||
                        !e.target.parentElement?.contains(e.relatedTarget)
                      ) {
                        (e.target as HTMLButtonElement).classList.remove(
                          "show-menu"
                        );
                      } else {
                        e.target.focus();
                      }
                    }}
                  />
                  <div className="menu">
                    <a href={fullUrl} download target="_blank" rel="noreferrer">
                      <button className="menu-item">
                        <img src={IP.icon.download} alt="icon descargar" />
                        Descargar
                      </button>
                    </a>

                    {isAdmin() && (
                      <>
                        <button
                          className="menu-item"
                          onClick={() => {
                            setModal({ _action: "update", _url: fullUrl });
                          }}
                        >
                          <img
                            className="icon"
                            src={IP.icon.edit}
                            alt="icon editar"
                          />
                          Modificar
                        </button>
                        <button
                          className="menu-item"
                          onClick={() => {
                            setModal({ _action: "delete", _url: fullUrl });
                          }}
                        >
                          <img
                            className="icon"
                            src={IP.icon.delete}
                            alt="icon borrar"
                          />
                          Borrar
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <a
                  className="file-body"
                  href={fullUrl}
                  target="_blank"
                  style={{
                    backgroundImage: `url(${isrc})`,
                  }}
                  rel="noreferrer"
                  onClick={async (e) => {
                    await handleClick(e, fullUrl);
                  }}
                >
                  {""}
                </a>
              </div>
            );
          })}
        </div>
      )}

      {frame && (
        <PDFViewer
          _src={frame._src}
          _title={frame._title}
          _close={() => {
            URL.revokeObjectURL(frame._src);
            setFrame(null);
          }}
        />
      )}

      {/* -------------------------------------------------- MODAL CRUD */}
      {isAdmin() && modal && (
        <ModalCRUD
          _type="files"
          _action={modal._action}
          _STAction={{
            userID: userid || "error",
            studyID: data.data.id || studyid || "error",
            personID: employeeid,
          }}
          _onClick={() => setModal(null)}
          _origin={{ files: [...data.data.files] }}
          _controls={false}
          _originFile={modal._url}
          _cleanse
        />
      )}
    </div>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const AnalysisReviewScreen = styled(_AnalysisReviewScreen).attrs(
  (props: AnalysisReviewScreenProps): AnalysisReviewScreenProps => {
    return { ...props };
  }
)<AnalysisReviewScreenProps>`
  ${(props) => css`
    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 2.5rem;
      flex-grow: 1;
      margin: 0 -1rem;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 1rem;
      justify-content: center;
      align-items: flex-start;

      .file-card {
        width: 15rem;
        aspect-ratio: 1/1;
        border-radius: 2rem;
        box-shadow: var(--shadow-button);
        transition: box-shadow 0.15s;
        display: flex;
        flex-direction: column;

        .file-body {
          flex-grow: 1;
          background-image: url(${IP.icon.external_link});
          background-position: center 25%;
          background-repeat: no-repeat;
          background-size: 60%;
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        }

        .header-container {
          display: flex;
          position: relative;
          margin: 1rem;
          margin-bottom: 0;
          gap: 0.5rem;

          .icon-pdf,
          .button-menu {
            width: 1.5rem;
            height: 1.5rem;
          }

          .filename {
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
          }

          .button-menu {
            margin: 0;
            padding: 0;
            background-image: url(${IP.icon.menu});
            background-size: 1rem 1rem;
            background-repeat: no-repeat;
            background-position: center;
            aspect-ratio: 1;
            border: 0;
          }
        }
      }
    }

    .menu {
      opacity: 0;
      display: flex;
      flex-direction: column;
      transition: opacity 0.25s;
      border: 0;
      border-radius: 0.5rem;
      box-shadow: var(--shadow-button);
      position: absolute;
      width: max-content;
      left: 100%;
      top: 50%;
      z-index: 5;
      pointer-events: none;

      .menu-item {
        margin: 0;
        border: 0;
        border-radius: 0;
        border-bottom: 0.1rem solid #d4d4d4;
        color: #000000;
        text-align: start;
      }

      img {
        float: left;
        margin-right: 0.5rem;
        height: 100%;
      }
    }

    .button-menu.show-menu + .menu {
      opacity: 1;
      display: flex;
      pointer-events: initial;
    }

    .icon-search {
      background-image: url(${IP.icon.search});
    }

    .icon {
      margin-left: 0.2rem;
    }

    .file-card:hover {
      box-shadow: var(--shadow-medium);
      cursor: pointer;
    }

    .button-create {
      display: none;
      border-radius: 50%;
      width: 1rem;
      background-size: 2.3rem 2.3rem;
      background-repeat: no-repeat;
      background-position: center;
      float: right;
      margin-top: 2.32rem;
    }

    @media screen and (max-width: 750px) {
      .row .file-card {
        width: 12rem;
      }
    }
    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default AnalysisReviewScreen;
// #endregion
