import styled, { css } from "styled-components";
import { parseCSS } from "scripts/FunctionsBundle";
import React, { useEffect, useState } from "react";
import IP from "@utils/ImageProvider";
import { GS, resetPassword } from "App";

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
// ModalPass => Rename all instances to use (CTRL + SHIFT + L)
export type ModalPassProps = {
  _email: string | null;
  _onClick?: () => void;
  _afterChange?: () => void;
} & _Base;
// #endregion

// #region ##################################################################################### COMPONENT
const _ModalPass = (props: ModalPassProps) => {
  // ---------------------------------------------------------------------- HOOKS
  const [loading, setLoading] = useState("");
  const [email, setEmail] = useState<string | null>(props._email);

  // ---------------------------------------------------------------------- FUNCTION HANDLERS
  const handleClose = () => {
    setEmail(null);
    props._onClick?.();
  };

  useEffect(() => {
    setEmail(props._email);
  }, [props]);

  function handleAfterLoad(res: any) {
    setLoading("");

    if (res) {
      handleClose();
      GS.setAlert({
        _type: "success",
        _message: "Se ha enviado un correo de recuperación a: " + email,
      });

      props._afterChange?.();
    }
  }

  // ---------------------------------------------------------------------- HANDLE SUBMIT
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      GS.setAlert({
        _type: "warning",
        _message: "No se ha ingresado ninguna dirección de correo.",
      });

      return;
    }

    setLoading("Enviando...");
    resetPassword(email).then(handleAfterLoad);
  };

  // ---------------------------------------------------------------------- RETURN
  return (
    <>
      {/* -------------------------------------------------- OPEN BUTTON */}
      {loading && <div className="spinner">{loading}</div>}

      {/* -------------------------------------------------- MODAL CONTAINER */}
      {email && (
        <div className={props.className + " modal-container"}>
          {/* -------------------------------------------------- MODAL */}
          <div className="modal">
            {/* ============================== TITLE */}
            <div className="modal-title">
              <h3>Reestablecer contraseña</h3>
              <button
                className="exit"
                onClick={handleClose}
                disabled={!!loading}
              >
                <img src={IP.icon.close} alt="close button" />
              </button>
            </div>

            <h6>{email}</h6>

            {/* ============================== INPUTS */}
            <form onSubmit={handleSubmit}>
              <p>
                Se enviará un correo a <b>{email}</b> para reestablecer su
                contraseña.
              </p>

              {/* ============================== MODAL CONTROLS */}
              <div className="modal-controls">
                <button
                  className="back"
                  onClick={handleClose}
                  disabled={!!loading}
                >
                  <img src={IP.icon.back} alt="previous" />
                  Regresar
                </button>
                <button className="login" disabled={!!loading}>
                  {loading ? "Enviando..." : "Enviar correo"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const ModalPass = styled(_ModalPass).attrs(
  (props: ModalPassProps): ModalPassProps => {
    return { ...props };
  }
)<ModalPassProps>`
  ${(props) => css`
    .wrapper.reversed > label {
      font-size: 1rem;
    }

    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default ModalPass;
// #endregion
