import styled, { css } from "styled-components";
import { fitString, parseCSS } from "scripts/FunctionsBundle";
import { LoaderData } from "@utils/ClassTypes";
import { useLoaderData, useLocation } from "react-router";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GS, isAdmin } from "App";

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
// Breadcrumbs => Rename all instances to use (CTRL + SHIFT + L)
type BreadcrumbsProps = {} & _Base;
// #endregion

// #region ##################################################################################### COMPONENT
const _Breadcrumbs = (props: BreadcrumbsProps) => {
  const data = useLoaderData() as LoaderData;
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    // Utility vars
    const arr: React.ReactNode[] = [];
    const [, ...temp] = location.pathname.split("/");
    let currPath = "";
    let prevPath = "admin";

    // Iterate in path elements
    for (let i = 0; i < temp.length; i += 2) {
      const item = temp[i];
      const key = temp[i + 1];
      if (!item || !key) continue;
      if (i !== 0 || isAdmin()) {
        arr.push(
          <Link to={currPath || "/"}>
            {fitString(
              data
                ? item === "users"
                  ? data.userName // "Usuarios"
                  : item === "studies"
                  ? data.studyName // "Análisis"
                  : item === "employees"
                  ? data.employeeName // "Empleados"
                  : item
                : GS.currentUser?.name || "Inicio",
              20,
              "end"
            )}
          </Link>
        );
      }

      currPath += `/${item}/${key}`;
      prevPath = item;
      // if (isAdmin()) arr.push(<Link to={currPath}>{fitString(key, 10, "end")}</Link>);
    }

    // Add possibly next action
    arr.push(
      prevPath === "admin"
        ? "Usuarios"
        : prevPath === "users"
        ? GS.currentUser?.isOrganization
          ? "Empleados"
          : "Análisis"
        : prevPath === "employees"
        ? "Análisis"
        : "Detalles"
    );

    setBreadcrumbs(arr);
  }, [data, location]);

  // ---------------------------------------------------------------------- RETURN
  return (
    <div className={props.className + " breadcrumbs"}>
      {breadcrumbs.map((el, i) => {
        return (
          <div key={i} className="item">
            {el}
          </div>
        );
      })}
    </div>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const Breadcrumbs = styled(_Breadcrumbs).attrs(
  (props: BreadcrumbsProps): BreadcrumbsProps => {
    return { ...props };
  }
)<BreadcrumbsProps>`
  ${(props) => css`
    // Ingresa aquí todos los estilos.
    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default Breadcrumbs;
// #endregion
