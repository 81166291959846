import styled, { css } from "styled-components";
import { parseCSS } from "scripts/FunctionsBundle";
import IP from "@utils/ImageProvider";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useState } from "react";

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
// PDFViewer => Rename all instances to use (CTRL + SHIFT + L)
type PDFViewerProps = {
  _src: string;
  _close: () => void;
  _title?: string;
} & _Base;
// #endregion

// #region ##################################################################################### COMPONENT
const _PDFViewer = (props: PDFViewerProps) => {
  const [pages, setPages] = useState(1);
  // ---------------------------------------------------------------------- RETURN
  return (
    <div className={props.className + " modal-container"}>
      <object type="application/pdf" data={props._src} className="pdf">
        <Document
          error={
            <>
              <h3>{props._title || "Archivo"}</h3>
              <a
                className="as-button login"
                download={props._title}
                href={props._src}
                target={"_blank"}
                rel="noreferrer"
              >
                Abrir archivo
              </a>
              <p>No ha sido posible mostrar la vista previa</p>
            </>
          }
          externalLinkTarget={"_blank"}
          loading={<div className="spinner">Cargando...</div>}
          file={props._src}
          className={"pdf viewer"}
          onLoadSuccess={(pdf) => {
            // pdf.getData(); // WIP
            setPages(pdf.numPages);
          }}
        >
          <h3 className="mbot">{props._title || "Archivo"}</h3>
          <a
            className="as-button login"
            download={props._title}
            href={props._src}
            target={"_blank"}
            rel="noreferrer"
          >
            Descargar archivo
          </a>
          <div className="pdf-container">
            {new Array<number>(pages).fill(0).map((_, i) => {
              return (
                <Page
                  key={i}
                  canvasBackground={undefined}
                  className={"pdf-page"}
                  error={"Error al cargar la página"}
                  pageIndex={i}
                  width={window.screen.width - 10}
                />
              );
            })}
          </div>
        </Document>
      </object>

      <button className="frame-close" onClick={props._close}>
        <img src={IP.icon.close} alt="close frame" />
      </button>
    </div>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const PDFViewer = styled(_PDFViewer).attrs(
  (props: PDFViewerProps): PDFViewerProps => {
    return { ...props };
  }
)<PDFViewerProps>`
  ${(props) => css`
    .pdf {
      width: 90%;
      height: 100%;
      margin: auto;
      box-shadow: var(--shadow-medium);
      overflow: auto;
    }

    .pdf.viewer {
      background-color: var(--color-shadow-white);
      display: flex;
      flex-direction: column;
      height: calc(100% - 0.5rem);
      margin-top: 0.5rem;
      text-align: center;

      h3.mbot {
        margin-bottom: 0;
      }
    }

    .react-pdf__message,
    .react-pdf__message--error {
      display: flex;
      flex-direction: column;
      margin: auto 0;
    }

    .pdf-page {
      box-shadow: var(--shadow-small);
      margin: 0.5rem 0;
    }

    .pdf-container {
      flex-grow: 1;
      height: 100%;
      overflow: auto;
    }

    .frame-close {
      position: absolute;
      right: 0%;
      line-height: 0;
      border-radius: 50%;
      border: none;
      height: min-content;
      padding: 0.5rem;
      margin: 0.5rem;
      top: 0;

      img {
        width: 2rem;
      }
    }

    @media screen and (max-width: 750px) {
      .frame-close img {
        width: 1rem;
      }

      .pdf {
        width: 100%;
      }
    }

    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default PDFViewer;
// #endregion
